
import { defineComponent, getCurrentInstance, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { getRepositoryDetail } from '@/api/repository';
import ServiceDepend from '../components/depend/Index.vue';
import ServiceBase from './Base.vue';
import ServiceHistory from './History.vue';
import { getServiceList } from '@/api/servers';
import { parseRepositoryAppInfo } from '@/views/service-repository/detail/util';
import { getServiceShowName } from '@/views/service-management/components/utils';
import ServiceName from '@/views/service-management/components/ServiceName.vue';

export default defineComponent({
  name: 'RepositoryPlatformDetail',
  components: { ServiceDepend, ServiceBase, ServiceHistory, ServiceName },
  setup() {
    const route = useRoute();
    const currentServiceId = ref(Number(route.params.id));
    const serverList = reactive([] as any[]);
    const getServerList = async () => {
      const { data } = await getServiceList({ all: true });
      data.rows.forEach((x: any) => {
        // eslint-disable-next-line no-param-reassign
        x.shortName = x.name ? getServiceShowName(x.name) : '';
      });
      serverList.length = 0;
      serverList.push(...(data.rows || []));
      currentServiceId.value = Number(route.params.id);
    };

    getServerList();

    const showSelect = ref(route.fullPath.includes('service-list'));

    const activeTab = ref('base');
    const serviceDependRef = ref(null as any);
    const serviceInfo = ref(null as any);
    const isService = ref(route.name === 'ServiceDetail');
    const loading = ref(true);
    const fetchData = async () => {
      loading.value = true;
      // 判断服务类型
      let data = null;
      const res = await getRepositoryDetail(currentServiceId.value as any, 2);
      data = parseRepositoryAppInfo(res.data);
      serviceInfo.value = data;
      serviceInfo.value.isApp = true;
      loading.value = false;
    };
    fetchData();

    const { proxy } = getCurrentInstance() as any;
    const activedDependTab = ref(false);
    watch(activeTab, (tab) => {
      if (tab === 'depend' && !activedDependTab.value) {
        serviceDependRef.value.render(serviceInfo.value.name, serviceInfo.value.serviceVersion, 'app');
        activedDependTab.value = true;
      }
    });
    const selectService = (res: any) => {
      proxy.$router.replace({
        path: `/services/business/detail/${res}`,
      });
      activeTab.value = 'base';
      activedDependTab.value = false;
      fetchData();
      proxy.$forceUpdate();
    };
    return {
      activeTab,
      serviceDependRef,
      serviceInfo,
      loading,
      isService,
      currentServiceId,
      serverList,
      selectService,
      showSelect,
    };
  },
});
